<template>
  <div class="echart" id="echart-StudentPassRateBar" :style="{ float: 'left', width: '100%', height: '100%' }"></div>
</template>

<script>
import echarts from 'echarts';
export default {
  name: 'StudentPassRateBar',
  data() {
    return {
      labelOption: '',
      app: {},
      xData : ['本期合格率', '本年合格率', '去年合格率'],
      yData : [60, 80, 90]
    };
  },
  methods: {
    initChart() {
      let getchart = echarts.init(document.getElementById('echart-StudentPassRateBar'));
      var StudentPassRateBarOption = {
        grid: {
          top: '25%',
          left: '5%',
          bottom: '5%',
          right: '5%',
          containLabel: true,
        },
        tooltip: {
          show: true,
        },
        animation: false,
        xAxis: [
          {
            type: 'category',
            data: this.xData,
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show:false,
              lineStyle: {
                color: "#fff",
                fontSize:11
              },
            },
            axisLabel: {
              textStyle: {
                color: '#fff',
                fontSize: 12,
              },
              margin: 20,
            },
          },
        ],
        yAxis: {
          type: 'value',
          min: 0,
          max: 100,
          position: 'left',
          offset: 0,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#fff",
              fontSize:11
            },
          },
          axisLabel: {
            show: false,
            formatter: '{value}',
          },
          //网格线
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [20, 10],
            symbolOffset: [0, -6],
            symbolPosition: 'end',
            z: 12,
            // "barWidth": "0",
            label: {
              normal: {
                show: true,
                position: 'top',
                // "formatter": "{c}%"
                fontSize: 15,
                fontWeight: 'bold',
                color: '#FFFFFF',
                textShadow :'#FFFFFF 10px 10px 10px'
              },
            },
            color: '#1772BA',
            data: this.yData,
          },
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [20, 10],
            symbolOffset: [0, 7],
            // "barWidth": "20",
            z: 12,
            color: '#185CA2',
            data: this.yData,
          },
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [30, 15],
            symbolOffset: [0, 12],
            z: 10,
            itemStyle: {
              normal: {
                color: 'transparent',
                borderColor: '#2EA9E5',
                borderType: 'solid',
                borderWidth: 1,
              },
            },
            data: this.yData,
          },
          {
            type: 'bar',
            //silent: true,
            barWidth: '20',
            barGap: '10%', // Make series be overlap
            barCateGoryGap: '10%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
                  {
                    offset: 0,
                    color: '#1964B0',
                  },
                  {
                    offset: 1,
                    color: '#134884',
                  },
                ]),
                opacity: 0.8,
              },
            },
            data: this.yData,
          },
        ],
      };

      getchart.setOption(StudentPassRateBarOption);
      //随着屏幕大小调节图表
      window.addEventListener('resize', () => {
        getchart.resize();
      });
    },

  },
  mounted() {
    this.initChart();
  },
};
</script>

<style scoped></style>
