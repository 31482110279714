<template>
  <div class="head">
    <!--  标题  65-->
    <div class="bt">
      <p class="bt1">培训情况统计分析</p>
    </div>
    <!--  公告 45+10 -->
    <div class="box">
      <div class="marquee-box" ref="marquee" @mouseover="menter" @mouseleave="mleave">
        <p ref="cmdlist" class="pWidth">
          张淑芬教师加入机构1教师团队
        </p>
      </div>
    </div>
    <!--  内容  -->
    <div class="middle">
      <div class="m-left">
        <!--    招生人次      -->
        <div style=" height: calc((100% - 20px) * 0.20)">
          <div class="title">招生人次</div>
          <div class="admissionsPeople1">
            <div class="inset">
              0
            </div>
            <div class="inset1">
              0
            </div>
            <div class="inset2">
              1
            </div>
            <div class="inset3">
              5
            </div>
            <div class="inset4">
              3
            </div>
            <div class="inset5">
              2
            </div>
          </div>
        </div>
        <!--    学员合格率    -->
        <div style=" height: calc((100% - 20px) * 0.30);position: relative;margin-top: 10px">
          <div class="title">学员合格率</div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap">
            <student-pass-rate-bar style="width:100% ;height: 100%"/>
          </div>
        </div>
        <div style="height: calc((100% - 20px) * 0.50);position: relative;margin-top: 10px ">
          <!--    招生，报考，结业趋势    -->
          <div class="title">招生,报考,结业趋势</div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap">
            <period/>
          </div>
        </div>
      </div>
      <div class="m-middle">
        <!--        中间图-->
        <div style="" class="org">
          <div class="hint">
            <img :src="hint" width="100%" height="100%">
            <div class="hint1">
              机构数量:13
            </div>
          </div>

          <div class="NumberOfTeachers">
            <img :src="hint" width="100%" height="100%">
            <div class="NumberOfTeachers1">
              教师数量:13
            </div>
          </div>
          <div class="desc-wrap" style="">
            <!--     招生人次环比       -->
            <div class="desc">
              <p align="center">
                <span style="color: rgb(0,255,255);font-size:14px">80 </span>
                <span>&nbsp;</span>
                <span style="color: springgreen;font-size:11px">70%▲</span>
              </p>
              <p slot="title" align="center" style="color: rgb(0,255,255);font-size:14px">招生人次环比</p>
            </div>

            <!--   开班数量环比  -->
            <div class="desc">
              <p align="center">
                <span style="color: rgb(0,255,255);font-size:14px">80 </span>
                <span>&nbsp;</span>
                <span style="color: springgreen;font-size:11px">70%▲</span>
              </p>
              <p slot="title" align="center" style="color: rgb(0,255,255);font-size:14px">开班数量环比</p>
            </div>

            <!--      报考人次环比      -->
            <div class="desc">
              <p align="center">
                <span style="color: rgb(0,255,255);font-size:14px">80 </span>
                <span>&nbsp;</span>
                <span style="color: springgreen;font-size:11px">70%▲</span>
              </p>
              <p slot="title" align="center" style="color: rgb(0,255,255);font-size:14px">报考人次环比</p>
            </div>
            <!--      人均学时环比      -->
            <div class="desc">
              <p align="center">
                <span style="color: rgb(0,255,255);font-size:14px">80 </span>
                <span>&nbsp;</span>
                <span style="color: springgreen;font-size:11px">▲70%</span>
              </p>
              <p slot="title" align="center" style="color: rgb(0,255,255);font-size:14px">人均学时环比</p>
            </div>
          </div>

        </div>
        <div style="height: calc((100% - 10px)  * 0.38);position: relative;margin-top: 10px">
          <!--    列表    -->
          <border style="width: 100%;height: 100%"/>
          <div class="echart-wrap">
            <div class="list-child" style="position: absolute;top: -25px;left: 0;">
              <div>机构名称</div>
              <div>当前教师</div>
              <div>当前学员</div>
              <div>招生人数</div>
              <div>开班数量</div>
              <div>报考人数</div>
            </div>
            <div class="marquee-wrap">
              <div class="marquee-list" :class="{'animate-up': animateUp}">
                <div class="list-child" v-for="(item, index) in yuanData" :key="index">
                  <div>{{item.name}}</div>
                  <div>{{item.dqjss}}</div>
                  <div>{{item.dqxys}}</div>
                  <div>{{item.ljzsrs}}</div>
                  <div>{{item.ljkbsl}}</div>
                  <div>{{item.ljbkrs}}</div>
                </div>
              </div>

            </div>
            <!--            <test-table/>-->
          </div>
        </div>

      </div>
      <div class="m-right">
        <div style=" height: calc((100% - 20px) * 0.31);position: relative">
          <!--    教师学员年龄分布    -->
          <div class="title">教师学员年龄分布</div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap" style="height: 70%">
            <age-distribution-radar/>
          </div>
        </div>
        <div style=" height: calc((100% - 20px) * 0.31);position: relative;margin-top: 10px">
          <!--   教师学员学历分布    -->
          <div class="title">教师学员学历分布</div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap">
            <degree-distribution-bar/>
          </div>
        </div>
        <div style=" height: calc((100% - 20px) * 0.38);margin-top: 10px;position: relative">
          <!--    学时趋势    -->
          <div class="title">学时趋势</div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="echart-wrap">
            <school-trends-line/>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import bt from '@/static/img/标题.png'
  import gg from '@/static/img/边框1.png'
  import lb from '@/static/img/lb.png'
  import content from '@/static/img/content.png'
  import org from '@/static/img/机构.png'
  import hint from '@/static/img/hint.png'
  import content1 from '@/static/img/content1.png'
  import period from './period'
  import ageDistributionRadar from "@/view/trainingSituation/ageDistributionRadar";
  import degreeDistributionBar from "@/view/trainingSituation/degreeDistributionBar";
  import SchoolTrendsLine from "@/view/trainingSituation/SchoolTrendsLine";
  import testTable from "@/view/trainingSituation/trainingSituationTable";
  import border from "../../components/border.vue"
  import StudentPassRateBar from './StudentPassRateBar'

  export default {
    name: "trainingSituation",
    components: {
      border,
      period,
      ageDistributionRadar,
      StudentPassRateBar,
      degreeDistributionBar,
      SchoolTrendsLine,
      testTable
    },
    data() {
      return {
        bt: bt, //标题图片
        gg: gg, //公告图片
        lb: lb, //喇叭图片
        org: org, //机构图片
        hint: hint, //提示图片
        content: content, //内容图片
        content1: content1,
        value: 0,
        timer: '0',//计时器
        timer1: '0',//
        pwidth: 0,//公告文本的宽度
        animateUp: false,
        windowWidth: document.documentElement.clientWidth,// 设备屏幕的宽度
        height: window.innerHeight + 'px',
        yuanData: [
          {
            name: '机构1',
            dqjss: '52',
            dqxys: '51',
            ljzsrs: '233',
            ljkbsl: '455',
            ljbkrs: '44'
          },
          {
            name: '机构2',
            dqjss: '52',
            dqxys: '51',
            ljzsrs: '233',
            ljkbsl: '455',
            ljbkrs: '44'
          },

          {
            name: '机构3',
            dqjss: '52',
            dqxys: '51',
            ljzsrs: '233',
            ljkbsl: '455',
            ljbkrs: '44'
          },

          {
            name: '机构4',
            dqjss: '52',
            dqxys: '51',
            ljzsrs: '233',
            ljkbsl: '455',
            ljbkrs: '44'
          },

        ]
      }
    },
    watch: {
      value(newValue, oldValue) {
        let allWidth = parseInt(this.windowWidth) + parseInt(this.pwidth[0]);
        if (newValue <= -allWidth) {
          this.$refs.cmdlist.style.marginLeft = this.windowWidth + "px";
          this.value = 0;
        }
      },
    },
    methods: {
      clickCommend(e) {
        let _this = this;
        this.$nextTick(() => {
          this.value -= 1;
          this.$refs.cmdlist.style.marginLeft = _this.windowWidth + this.value + "px";
        });
      },
      menter() {
        clearInterval(this.timer);
      },
      mleave() {
        this.timer = setInterval(this.clickCommend, 0);
      },
      scrollAnimate() {
        this.animateUp = true
        setTimeout(() => {
          this.yuanData.push(this.yuanData[0]);
          this.yuanData.shift();
          this.animateUp = false
        }, 500)
      }
    },
    destroyed() {
      clearInterval(this.timer);
      clearInterval(this.timer1)
    },
    mounted() {
      this.timer = setInterval(this.clickCommend, 10);
      this.timer1 = setInterval(this.scrollAnimate, 1500);
    }
  }
</script>

<style scoped lang="scss">
  .head {
    background: radial-gradient(ellipse, #00102E 0, #000B27 100%);
    height: 100%;
    overflow-y: hidden;

  }

  .bt {
    background-image: url("../../static/imgs/标题.png");
    background-size: 100% 45px;
    background-repeat: no-repeat;
    background-position: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;

  }

  .bt1 {
    color: rgb(0, 255, 255);
    font-size: 27px;

  }

  .box {
    width: calc(100% - 20px);
    border: 1px solid #2163CA;
    padding-left: 55px;
    padding-right: 20px;
    height: 45px;
    margin: auto 10px auto auto;
    position: relative;
    box-shadow: inset 0 0 20px 1px #2163CA;

    &::before {
      position: absolute;
      left: 10px;
      top: 10px;
      content: " ";
      width: 25px;
      height: 25px;
      background-image: url("../../static/imgs/lb.png");
      background-size: 100%;
      background-repeat: no-repeat;
    }

    /*公告内容*/
    .marquee-box {
      overflow: auto;

      .pWidth {
        line-height: 45px;
        color: rgb(0, 255, 255);
        display: block;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        font-family: 微软雅黑, serif;
        fontSize: 14px;
      }
    }
  }

  .middle {
    display: flex;
    padding: 0 10px;
    height: calc(100% - 130px);
    margin-top: 10px;

    .m-left {
      width: calc((100% - 20px) * 0.19);
      display: flex;
      flex-direction: column;
    }

    .m-middle {
      width: calc((100% - 20px) * 0.5);
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      margin-right: 10px;
    }

    .m-right {
      width: calc((100% - 20px) * 0.19);
      display: flex;
      flex-direction: column;
    }
  }

  .echart-wrap {
    position: absolute;
    top: 50px;
    z-index: 999;
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center
  }


  .title {
    color: white;
    background-color: rgb(0, 26, 77);
    text-align: center;
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  ::-webkit-scrollbar {
    width: 0 !important;

  }

  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0;

  }

  /*招生人次*/
  .admissionsPeople {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .admissionsPeople1 {
    width: 100%;
    height: calc(100% - 40px);
    background-color: rgb(0, 20, 61);
    position: relative;
  }

  /*学员合格率*/
  .StudentPassRate {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  /*学员合格率*/
  .StudentPassRate1 {
    width: 100%;
    height: 100%;

    position: relative;
  }

  .StudentPassRate2 {
    position: absolute;
    z-index: 999;
    bottom: 0;
    left: 0;

  }

  /*招生，报考，结业趋势*/
  .tendency {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .tendency1 {
    width: 100%;
    height: 100%;
    position: relative;

  }

  .tendency2 {
    width: 94%;
    height: 90%;

    position: absolute;
    bottom: 0;

    left: 0;

  }

  /*教师学员年龄分布*/
  .ageDistribution {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .ageDistribution1 {
    width: 100%;
    height: 100%;

    position: relative;


  }

  .ageDistribution2 {
    width: 92%;
    height: 92%;

    position: absolute;
    bottom: 0;

    left: 2%;

  }

  /*教师学员学历分布*/
  .degreeDistribution {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .degreeDistribution1 {
    width: 100%;
    height: 100%;

    position: relative;

  }

  .degreeDistribution2 {
    width: 92%;
    height: 92%;

    position: absolute;
    bottom: 0;

    left: 2%;

  }

  /*学时趋势*/
  .SchoolTrends {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;

  }

  .SchoolTrends1 {
    width: 100%;
    height: 100%;

    position: relative;


  }

  .SchoolTrends2 {
    width: 92%;
    height: 88%;

    position: absolute;
    bottom: 5%;

    left: 2%;

  }

  /*机构*/
  .org {
    width: 100%;
    background-color: rgb(0, 18, 53);
    height: calc((100% - 10px) * 0.62);
    position: relative;

    &::before {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 20%;
      right: 0;
      margin: auto;
      background-image: url("../../static/img/机构.png");
      background-size: 60% auto;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    .hint {
      width: 130px;
      height: 50px;
      position: relative;
      top: 11%;
      left: 62%;
    }

    .hint1 {
      width: 130px;
      height: 50px;
      position: absolute;
      color: rgb(0, 255, 255);
      top: 20%;
      left: 25%;
    }

    .desc-wrap {
      position: absolute;
      bottom: 15px;
      left: 0;
      width: 100%;
      padding: 0 10px;
      height: 20%;
      display: flex;
      justify-content: space-around;

    }

    .desc {
      background-image: url("../../static/img/content1.png");
      background-size: 100%;
      background-repeat: no-repeat;
      padding: 10px;
    }

  }


  /*招生人次环比*/
  .AttendanceMonth {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 6%;

  }

  /*开班数量环比*/
  .classesSequential {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 29%;

  }

  /*报考人次环比*/
  .MonthlyApplicants {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 53%;

  }

  /*人均学时环比*/
  .PerCapitaClassHours {
    width: 170px;
    height: 100px;

    position: absolute;
    bottom: 7%;

    left: 77%;

  }

  .column {
    width: 100%;
    height: 100%;

    position: relative;


  }

  .column1 {
    width: 95%;
    height: 87%;

    position: absolute;
    top: 9%;

    left: 2%;

  }

  .AttendanceMonth1 {

    position: absolute;
    top: 10%;

    left: 16%;

  }


  /*教师数量*/
  .NumberOfTeachers {
    width: 130px;
    height: 50px;
    position: relative;
    top: 10%;
    left: 15%;

  }

  .NumberOfTeachers1 {
    width: 130px;
    height: 50px;

    position: absolute;
    color: rgb(0, 255, 255);

    top: 20%;

    left: 18%;

  }

  .NumberOfTeachers img {

    transform: rotateY(180deg);

  }

  /*招生人次*/
  .inset {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 6%;
    font-size: 23px;
    text-align: center;

  }

  .inset1 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 21%;
    font-size: 23px;
    text-align: center;

  }

  .inset2 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 36%;
    font-size: 23px;
    text-align: center;

  }

  .inset3 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 52%;
    font-size: 23px;
    text-align: center;

  }

  .inset4 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 68%;
    font-size: 23px;
    text-align: center;

  }

  .inset5 {
    width: 9%;
    height: 35px;

    box-shadow: 0px 0px 5px #4185F0 inset;

    color: rgb(0, 255, 255);

    position: absolute;
    top: 20%;

    left: 84%;
    font-size: 23px;
    text-align: center;

  }

  .list-child {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 14px;
    color: white;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    div{
      flex: 1;
      text-align: center;
    }
  }

  .marquee-wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;

    .marquee-list {
      li {
        width: 100%;
        height: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 20px;
        list-style: none;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
      }
    }
    .animate-up {
      transition: all 0.5s ease-in-out;
      transform: translateY(-40px);
    }
  }
</style>
